@import '../../../styles/responsive.scss';

.balance {
  &.break {
    margin: 0px;
  }
  &.columns {
    margin: 0px;
  }
}
.FAQ-box-sibling.columns:not(:last-child) {
  margin-bottom: 184px;
}
.FAQ-box {
  position: absolute;
  bottom: 0;
  width: 84%;
}
.fas .fa-chevron-right {
  color: black;
}
.mainnet-notifcation {
  color: #353e47;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
}
.balance-text {
  @include respond-above(md) {
    max-width: 90%;
  }
}
