@import '../../../../styles/responsive.scss';

.convert-tab {
  @include respond-below(md) {
    padding-left: 1rem;
  }
}
.convert-table-icon {
  padding-left: 0;
}
// remove border from first and last table element
.convert.table {
  thead {
    th {
      border-width: 0 0 1px;
      &.convert-table-type {
        padding-left: 0;
      }
      td {
        border-width: 0 0 1px;
      }
    }
  }
  tbody {
    tr {
      th:first-child {
        border: 0;
        padding: 0 0 0 3.2rem;
      }
      td:last-child {
        border: 0;
        padding: 0 0 0 3.2rem;
      }
    }
  }
}
