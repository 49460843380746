.convert-table-icon {
  padding-left: 0;
}
.standard-table {
  &.table {
    font-weight: 600;
    thead {
      th {
        padding: 1rem;
        border-width: 0 0 1px;
        &:nth-child(2) {
          padding-left: 0;
        }
        td {
          border-width: 0 0 1px;
        }
        abbr {
          text-decoration: none;
          font-size: 10px;
        }
      }
    }
    tbody {
      tr {
        th:first-child {
          // border: 0;
          padding: 0 0 0 3.2rem;
        }
        td:first-child {
          padding-left: 0;
        }
        td:last-child {
          // border: 0;
          padding: 0 0 0 3.2rem;
        }
      }
    }
  }
}
