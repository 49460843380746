.buttons {
  .button:not(:last-child):not(.is-fullwidth) {
    margin-right: 1rem !important;
  }
}

.button {
  font-weight: 600;
  &.is-large {
    padding: 20px 65px;

    &:not(.is-static) {
      width: 120px;
      @include respond-below(md) {
        width: 120px;
      }
    }
  }

  &.is-medium {
    padding: 18px 15px;
  }

  &.is-primary.is-outlined {
    background-color: white;
    color: $primary;
  }

  &.is-wide-button {
    padding: 20px 36px;
  }

  &.is-disabled {
    color: $primary !important;
    background-color: white !important;
    cursor: not-allowed;
  }
}
.button.has-text-left {
  justify-content: start;
}
.button.has-text-right {
  justify-content: end;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: rotate 1s linear infinite;
}
