@import '../../styles/colorPallete.scss';
@import '../../styles/responsive.scss';

.columns.navbar-top-table-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 22px !important;
  max-height: 60px;
  .column {
    &.navbar-top-table-item {
      padding: 2px 17px;
  }
}

  .navbar-top-table-box {
    .top-txt {
      color: #ffc2b8;
      font-size: 10px;
      font-weight: 700;
      letter-spacing: -0.1px;
    }
    .middle-txt{
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      line-height: 20px;
    }
    .bottom-txt {
      color: #ffc2b8;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 0px;
    }
    .media-left {
      margin-right: 15px;
    }
    figure {
      max-width: 45px;
      max-height: 45px;
    }
  }
}