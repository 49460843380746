//@import '../../../styles/common';
@import '../../../styles/responsive';
@import '../../../styles/colorPallete';

.cirus-staking-statistics {
  .amount-box.is-small .bottom-txt {
    padding-bottom: 0;
    color: $dull;
  }
  @include respond-below(sm) {
    margin: 0px;
    .column .amount-box .media-content {
      padding-left: 0px;
      padding-right: 6px;
    }
    .column:not(:first-child) .amount-box .media-content {
      border-left: 1px solid #f0f2f4;
      padding-left: 24px;
    }
  }
  @include respond-above(sm) {
    .column .amount-box .media-content {
      &:first-child {
        padding-left: 0px;
        padding-right: 24px;
        border-right: 1px solid #f0f2f4;
      }
    }
  }
  .amount-box.is-small .bottom-txt {
    padding-bottom: 0;
  }
  @include respond-above(md) {
    .column:last-child .amount-box .media-content {
      border: 0;
    }
  }
  .column:first-child {
    padding-left: 0;
  }
}
.stat-tile {
  margin: 0;
  padding: 45px 20px;
  @include respond-below(lg) {
    padding: 30px 20px;
  }
  @include respond-below(sm) {
    margin-left: 0;
  }

  &:not(:last-child) {
    border-right: 1px solid #f0f0f0;
    @include respond-below(sm) {
      border-right: 0px;
      border-bottom: 1px solid #f0f0f0;
    }
  }
}
.secondary-staking-statistics {
  margin: 40px 0 0 0;
  margin-bottom: 0;
  border-top: 1px solid #f0f0f0;
}
.staking.button {
  height: 40px;
  background-color: transparent;
  width: 7rem;
  &.full {
    position: absolute;
    top: 10px;
    right: 12px;
    z-index: 5;
    @include respond-below(md) {
      // top: 49px;
    }
    @include respond-below(sm) {
      height: 32px;
      position: relative;
      top: 0px;
      right: 0px;
    }
  }
}
.dropdown.fixed-200 .button span {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}
.community-staking {
  .mobile-alignment {
    @include respond-below(sm) {
      text-align: center;
      &.title-image-container {
        display: flex;
        justify-content: center;
      }
    }
  }
  .title-image {
    &.box {
      width: fit-content;
    }
    height: 145px;
    @include respond-below(sm) {
      height: 72.5px;
    }
  }
  .stat-tile {
    margin-bottom: 0;
    .column.is-narrow {
      @include respond-above(sm) {
        border-left: 1px solid #f0f2f4;
      }
      @include respond-below(sm) {
        border-top: 1px solid #f0f2f4;
      }
    }
    .column.no-border {
      border: none;
    }
  }
}
@include respond-below(sm) {
  .cirus-staking-statistics {
    .column {
      width: auto;
    }
  }
  .community-staking {
    .cirus-staking-statistics {
      .mobile-staking {
        padding-left: 0;
        padding-right: 0;
        .media {
          .media-content {
            overflow-x: visible;
            padding-left: 0;
            border: none;
            text-align: center;
          }
        }
      }
    }
    .title.is-2 {
      text-align: center;
    }
  }
}
.want-to-stat {
  padding: 32px;
  border-left: 1px solid #f0f0f0;
  @include respond-below(sm) {
    border-top: 1px solid #f0f0f0;
    border-left: 0;
  }
}
// resize taking selection dropdown
.field-body {
  .field:first-child.staking-select {
    @include respond-above(sm) {
      width: 0%;
      margin-right: 20px;
    }
  }
}
//drop down is-open changes
.dropdown {
  &.is-top-rounded.is-rounded {
    .button {
      border-radius: 40px 40px 0px 0px;
      border-width: 2px;
      border-bottom: 0;
    }
  }
  .dropdown-content {
    border-radius: 0px 0px 40px 40px;
    border: 2px solid #dbdbdb;
    border-top: 0px;
    padding: 0;
    overflow: hidden;
  }
}
// adjust staking option card ~ 'i want to' card
.staking-option {
  @include respond-above(sm) {
    padding: 28px 35px;
  }
  @include respond-below(sm) {
    padding: 30px 21px;
  }
  .drop-down.fixed-200 {
    min-width: 150px;
  }
}
// remove desktop bottom margin "i want to"
.staking-option {
  margin-bottom: 0;
  @include respond-below(sm) {
    padding-top: 15px;
  }
  .staking-label {
    padding-left: 40px;
    padding-top: 0;
    margin: 0;
    @include respond-below(sm) {
      padding-left: 21px;
      margin: 30px 0 0 0 !important;
    }
  }
  .quantity-stake.field.has-addons {
    .control:not(:last-child) {
      input {
        border-radius: 0 40px 40px 0;
      }
    }
    position: relative;
  }
}

.column {
  &.your-wallet {
    padding-left: 0;
    @include respond-below(sm) {
      padding-right: 0;
    }
    .your-wallet-info {
      display: flex;
      align-items: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      font-weight: 600;
      white-space: nowrap;
      border-radius: 40px;
      border: 1px solid #d5d5d5;
      padding: 15px calc(calc(0.75em - 1px) + 0.375em);
      @include respond-below(sm) {
        padding: 9px calc(calc(0.75em - 1px) + 0.375em);
      }
      span {
        &:hover {
          cursor: default;
        }
      }
    }
  }
  &.choose-staker {
    padding-right: 0;
    @include respond-below(sm) {
      padding-left: 0;
    }
  }
}

.staking-input {
  position: relative;
  .control:last-child {
    &input {
      border-radius: 0 40px 40px 0;
    }
  }
}

.transaction-modal-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: unset;
  max-height: 300px;
}

.textCenter {
  text-align: center;
}

.button-modal-error {
  margin-top: auto;
}
