@import '../../../styles/responsive.scss';
.notifications {
  padding-left: 40px;
  padding-right: 40px;
  @include respond-below(md) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .columns {
    margin: 0;
    margin-bottom: 0;
    &:not(:last-child) {
      border-bottom: 1px solid #f0f2f4;
    }
  }
  p {
    padding: 25px 0px;
  }
}
.tabs.notifs {
  ul {
    li {
      a {
        padding-bottom: 20px;
      }
    }
  }
}
