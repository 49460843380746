.desktop-top-table {
  .table {
    tr td:first-child {
      border-left: 0;
    }
    tr td:last-child {
      border-right: 0;
    }

    tr:first-child td {
      border-top: 0;
    }
    tr:last-child td {
      border-bottom: 0;
    }
  }
}
