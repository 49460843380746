@charset "utf-8";
@import './responsive.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&display=swap');
@import './bulmaVariables.scss';
@import './typography.scss';
@import './colorPallete.scss';
@import '../../node_modules/bulma/bulma.sass';
@import '../../node_modules/bulma-steps-component/bulma-steps.sass';
@import './components.scss';

html,
body {
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  background-color: rgb(245, 248, 251);
}

.notification {
  &.is-tight {
    padding: 12px;
    width: 320px;
    margin: 20px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.steps {
  .steps-marker.is-hollow {
    background-color: white;
  }
}

.statistics-box {
  padding: 0;

  .card-content {
    padding: 0;
  }

  .column {
    // padding: 20px 36px;

    &:not(:last-child) {
      border-right: 1px solid #f0f2f4;
    }
  }
}

button {
  &.styless {
    background-color: transparent;
    border: none;
  }
}
.no-focus {
  &:focus {
    border-color: #dbdbdb;
    box-shadow: none !important;
  }
  &:hover {
    border-color: #dbdbdb;
  }
}
