@import '../../styles/responsive.scss';
.inline-notification {
  position: relative;
  background: linear-gradient(
    90deg,
    #353e47 30%,
    rgba(53, 62, 71, 0.313333) 160%
  );
  // padding: 10px 35px 0 10px;
  border: 1px solid black;
  border-radius: 30px;
  color: white;
  height: 45px;

  .inline-notification-close {
    // position: absolute;
    z-index: 5;
    display: inline-block;
    top: 10px;
    right: 0px;
    width: 32px;
    height: 32px;
    font-size: 16px;
    background-color: transparent;
    color: white;
    border: none;
    border-radius: 9999px;
    cursor: pointer;
    pointer-events: auto;
    flex-grow: 0;
    flex-shrink: 0;
    vertical-align: top;
    user-select: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    &:hover {
      background-color: rgba(10, 10, 10, 0.2);
    }
  }

  strong {
    color: white;
  }

  .notification-icon-wrapper {
    position: relative;
    display: inline-block;
    height: fit-content;
    width: fit-content;

    .overlayed-notification-hint {
      position: absolute;
      width: 12px;
      height: 12px;
      background-color: rgb(238, 74, 92);
      border-radius: 20px;
      top: 10%;
      left: 47%;
      border: 1px solid white;
      @include respond-below(md) {
        display: none;
      }
    }
  }
}
