.state-indicator {
  .state-visual-indicator {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100px;
    margin-right: 4px;
  }

  &.is-active .state-visual-indicator {
    background-color: #17d800;
  }

  &.is-pending .state-visual-indicator {
    background-color: #ffdf00;
  }

  &.is-inactive .state-visual-indicator {
    background-color: #ff0000;
  }
}
