.dash-icon {
  height: 64px;
  width: 64px;
  &.has-background-primary-dull {
    background-color: $cirus-red-dull;
  }
  &.has-background-circle {
    border-radius: 50%;
  }
  &.has-border-white {
    border: 3px solid white;
  }
  .icon-size {
    height: 47px;
  }
}
