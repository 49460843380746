tr {
  height: 80px;
}
tr.info {
  height: 50px;
}
tr td.vcenter {
  vertical-align: middle;
}
tr th.vcenter {
  vertical-align: middle;
}

td.action p {
  text-transform: capitalize;
}
