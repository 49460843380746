@import '../../styles/common';
@import '../../styles/responsive.scss';

.dashboard {
  .cirus-staking {
    .column {
      width: 25%;
      @include respond-above(md) {
        padding-right: 24px;
      }
      overflow: visible;
      &:not(:first-child) {
        .media.amount-box {
          .column {
            .media-content {
              padding-left: 27px;
              border-left: 1px solid #f0f2f4;
              @include respond-below(md) {
                padding-left: 23px;
              }
            }
          }
        }
      }
    }
    .amount-box.is-small .bottom-txt {
      padding-bottom: 0;
    }

    .column:last-child .amount-box .media-content {
      border: 0;
    }

    .column:first-child {
      padding-left: 0;
    }
  }
}

.audit-image {
  margin-left: 160px;
  height: auto;
}

.card:hover {
  .cirus-staking-statistics .media-content {
    border-color: white;
  }
}
.card.cirus-staking-card {
  background-image: url('../../images/cirusTokenBackground.png');
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: bottom;
  @include respond-below(md) {
    background-position-y: 105%;
  }
  &.is-hoverable:hover {
    background-position-x: 100%;
    background-image: url('../../images/cirusTokenBackground.png'),
      $card-highlight-gradient;
  }
}
.card.mantra-dao-card {
  background-image: url('../../images/mantraDao.png');
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: bottom;
  @include respond-below(md) {
    background-position-y: 105%;
  }
  &.is-hoverable:hover {
    background-position-x: 95%;
    background-image: url('../../images/mantraDao.png'),
      $card-highlight-gradient;
  }
}

.card-container > .column {
  // Ensure cards have the same height as the heights card displayed
  display: flex;

  .card {
    width: 100%;
  }
}
