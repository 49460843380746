@import '../../styles/common';
@import '../../styles/responsive.scss';
@import '../../styles/colorPallete.scss';

.landing-page-header {
  margin-top: -80px;
  height: 306px;
  background-color: $primary;
  //background-image: url("../../images/cirusAnimatedOrb.png");
  background-size: 46%;
  background-position-x: 134%;
  background-repeat: no-repeat;
  background-position-y: 14%;
  background-repeat: no-repeat;
  @include respond-below(md) {
    background-image: none;
    height: 330px;
  }
}
.main-container.container {
  margin-top: 81px;
}
.inline-notification-container {
  padding: 8px 0px 29px 0px;
  margin: 0px;
  @include respond-below(sm) {
    padding-bottom: 18px;
  }
  &.columns:not(:last-child) {
    margin: 0px;
  }
  button.column {
    flex: none;
    // padding: 0px;
    width: 50%;
    @include respond-below(sm) {
      width: 100%;
    }
  }
}
.notifications-modal {
  padding: 0;
}
.scrolled-info-table-mobile {
  transition: height 0.3s ease;
  overflow: hidden;
}
