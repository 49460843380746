.tabs {
  > ul li {
    padding-left: 10px;
    padding-right: 10px;

    &:first-child {
      padding-left: 40px;
    }

    a {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 20px;
      border-bottom-width: 0px;
    }

    &.is-active a {
      border-bottom-width: 2px;
    }
  }
}
.standard-table {
  &.tabs {
    &:not(:last-child) {
      margin-bottom: 0;
    }
    ul {
      li:first-child {
        @include respond-below(md) {
          padding-left: 20px;
        }
      }
    }
  }
}
