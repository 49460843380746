.modal {
  .modal-content {
    min-height: 485.81px;
    .box {
      border-radius: 18px;
      height: 100%;
      margin-bottom: 0;
    }
    .modal-close-button {
      position: absolute;
      display: inline-block;
      background-color: white;
      top: 15px;
      right: 30px;
      width: 32px;
      height: 32px;
      font-size: 16px;
      border: none;
      border-radius: 9999px;
      cursor: pointer;
      pointer-events: auto;
      flex-grow: 0;
      flex-shrink: 0;
      vertical-align: top;
      user-select: none;
      -moz-appearance: none;
      -webkit-appearance: none;

      &:hover {
        background-color: rgba(10, 10, 10, 0.2);
      }
    }
  }
}
