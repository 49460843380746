@import 'src/styles/common';

.columns.balance-stack-statistics {
  margin-bottom: 40px;

  border-top: 1px solid #f0f2f4;
  border-bottom: 1px solid #f0f2f4;

  .column:last-child {
    border-left: 1px solid #f0f2f4;
  }

  .column {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
