.transfer {
  color: #353e47;
  &.subtitle {
    font-size: 16px;
    margin-bottom: 1.25rem;
  }
  &.sub {
    font-size: 16px;
  }
  &.note {
    font-size: 14px;
  }
  &.content {
    font-size: 12px;
  }
  &.break {
    margin: 30px 0px;
  }
  &.button {
    height: 40px;
    width: 134px;
  }
}

.transfer.modal {
  .modal-content .modal-close-button {
    position: absolute;
    display: inline-block;
    background-color: white;
    top: 50px;
    right: 50px;
    width: 32px;
    height: 32px;
    font-size: 16px;
    border: none;
    border-radius: 9999px;
    cursor: pointer;
    pointer-events: auto;
    flex-grow: 0;
    flex-shrink: 0;
    vertical-align: top;
    user-select: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
}
