.mantra-staking-form {
  .column {
    &:not(:last-child) {
      margin-right: 11px;
    }
    .button {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
