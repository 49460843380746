@import '../../styles/colorPallete.scss';
.token-background {
  background-color: $cirus-grey;
  border-radius: 999999px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-height: 18px;
    max-width: 18px;
    width: auto;
    height: auto;
  }
}
.make-mobile-table {
  font-weight: 600;
  .columns {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
}
.mobile-subtitle {
  font-size: 10px;
}
