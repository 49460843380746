// helpers
.vcenter {
  vertical-align: middle;
}
.is-round {
  border-radius: 100%;
}
.whitespace-nowrap {
  white-space: nowrap;
  word-break: keep-all;
  &.title {
    word-break: keep-all;
  }
}
.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.overflow-x-clip {
  overflow-x: clip;
}
.overflow-x-inherit {
  overflow-x: inherit;
}
.is-borderless {
  border: none !important;
}
// fonts, styles, etc
small {
  font-size: 10px;
  &.nav {
    font-size: 12px;
  }
}
strong {
  color: $primary;
}
a {
  font-family: 'Montserrat';
  color: $primary;
  font-weight: 600;
}

.title {
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: 40px;
  @include respond-below(md) {
    font-size: 25px;
  }
  &-smaller {
    font-family: 'Montserrat';
    font-weight: 800;
    font-size: 20px;
  }
  &.title.is-3 {
    @include respond-below(md) {
      font-size: 25px;
      white-space: nowrap;
    }
  }
  &.is-1 {
    &.welcome-msg {
      @include respond-below(md) {
        font-size: 25px;
        white-space: nowrap;
      }
    }
  }
}

.button {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  &.is-large:not(.is-static),
  &.is-medium:not(.is-static),
  &.is-small:not(.is-static) {
    font-size: 12px;
    font-weight: 600;
  }
  &.has-addons {
    .button.is-medium {
      font-size: 14px;
    }
  }
}
.tabs {
  > ul li {
    font-size: 14px;
    font-weight: bold;

    a {
      font-weight: bold;
      border-bottom-width: 0px;
    }
  }
}

.is-danger {
  &:not(button) {
    color: #f03a5f;
  }
}
