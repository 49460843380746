@import '../responsive.scss';
.amount-box {
  align-items: center;
  .media-left {
    padding: 0;
    margin-right: 22px;
    .image.columns {
      display: flex;
      justify-content: center;
      width: 54px;
      height: 54px;
      // padding-top: 8px;
      background-color: #f5f8fb;
      border-radius: 9999px;
      text-align: center;
      @include respond-below(sm) {
        width: 51px;
        height: 51px;
        border-radius: 25.5px;
      }
      img.column {
        width: 27px;
        // height: fit-content;
        @include respond-below(sm) {
          width: 27px;
        }
      }
    }
  }

  .top-txt {
    overflow: visible;
    font-family: 'Montserrat';
    font-size: 10px;
    font-weight: 700;
    color: inherit;
    white-space: nowrap;
  }

  .middle-txt {
    overflow: visible;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
  }

  .bottom-txt {
    overflow: visible;
    font-size: 10px;
    font-weight: 600;
    color: $dull;
    white-space: nowrap;
  }

  &.is-small {
    .top-txt,
    .bottom-txt {
      padding-bottom: 4px;
      font-size: 10px;
      // font-weight: normal;
    }

    .middle-txt {
      padding-bottom: 4px;
      font-size: 12px;
      // font-weight: normal;
    }
    &.mantra {
      @include respond-above(sm) {
        &-AP {
          .middle-txt {
            padding-bottom: 4px;
            font-size: 20px;
          }
        }
        &-TVL {
          .middle-txt {
            padding-top: 6px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.top-table-box.amount-box {
  height: 54px;
  .media-left {
    .image.columns {
      @include respond-below(md) {
        display: none;
      }
    }
  }
}
