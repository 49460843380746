@import './../../responsive.scss';
.card-container {
  margin-top: 13px;
  @include respond-below(sm) {
    margin-top: 2px;
  }
}
.card {
  border-radius: 10px;

  .card-content {
    padding: 45px;
    @include respond-below(md) {
      padding: 21px;
      padding-bottom: 28px;
      padding-top: 25px;
    }

    .loading-card {
      padding: 0px;
      margin-top: 10px;
    }

    .card-title {
      margin-top: 30px;
    }
  }

  &.has-small-padding .card-content {
    padding: 12px;
  }

  &.has-no-horizontal-padding .card-content {
    padding-left: 0;
    padding-right: 0;
  }

  &.has-large-vertical-padding {
    padding-top: 50px;
    padding-bottom: 50px;
    @include respond-below(md) {
      padding-top: 0px;
      padding-bottom: 134px;
    }
  }
  &.is-hoverable:hover {
    // background-image: $card-highlight-gradient;
    background-color: $card-highlight-gradient;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    background-image: $card-highlight-gradient;
    @include respond-below(sm) {
      background-position: 130% 105%;
    }

    .card-title,
    .card-text,
    .state-text,
    .title,
    .subtitle,
    .amount-box {
      color: white;
    }
    .cirus-staking-statistics .cirus-staking {
      .amount-box.is-small .bottom-txt {
        color: white;
      }
    }
    .cirus-staking {
      .column {
        width: fit-content;
        .media.amount-box {
          .column {
            .media-content {
              .bottom-txt {
                color: white;
              }
            }
          }
        }
      }
    }
  }
}
// align buttons across cards
.card.dashboard {
  height: 100%;
  position: relative;
  .status {
    background-color: #ee4a5c;
    border-radius: 9999px;
    color: white;
    font-weight: 900;
    position: absolute;
    top: 25px;
    right: 25px;
    padding: 2px 10px;
  }
  &:hover {
    .status {
      background-color: white;
      color: #ee4a5c;
      border: solid 1px #ee4a5c;
    }
  }
    .card-content {
    height: 100%;
    .dashboard {
      height: 100%;
      position: relative;
      .last-element {
        padding-bottom: 64px;
        margin-bottom: 0;
      }
      .button {
        position: absolute;
        bottom: 0px;
      }
    }
  }
}
