@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get(
      $breakpoints,
      $breakpoint
    ); // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get(
      $breakpoints,
      $breakpoint
    ); // Write the media query.
    @media (min-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}
$breakpoints: (
  xxs: 420px,
  xs: 576px,
  sm: 769px,
  md: 1024px,
  lg: 1200px,
  xl: 1440px,
);
.is-hidden-touch-desktop {
  @include respond-below(lg) {
    display: none;
  }
}
// responsive bulma-structured padding class
// at at desktop has the same padding as bulma class p/pt/pr/pb/pl etc, but padding is halved on mobile

// responsive-spacing
$responsive-spacing: 0.25rem;
// padding
.rp {
  &-0 {
    padding: $responsive-spacing * 0 !important;
    @include respond-below(sm) {
      padding: $responsive-spacing * 0 !important;
    }
  }
  &-1 {
    padding: $responsive-spacing * 1 !important;
    @include respond-below(sm) {
      padding: $responsive-spacing * 0.5 !important;
    }
  }
  &-2 {
    padding: $responsive-spacing * 2 !important;
    @include respond-below(sm) {
      padding: $responsive-spacing * 1 !important;
    }
  }
  &-3 {
    padding: $responsive-spacing * 3 !important;
    @include respond-below(sm) {
      padding: $responsive-spacing * 1.5 !important;
    }
  }
  &-4 {
    padding: $responsive-spacing * 4 !important;
    @include respond-below(sm) {
      padding: $responsive-spacing * 2 !important;
    }
  }
  &-5 {
    padding: $responsive-spacing * 6 !important;
    @include respond-below(sm) {
      padding: $responsive-spacing * 3 !important;
    }
  }
  &-6 {
    padding: $responsive-spacing * 12 !important;
    @include respond-below(sm) {
      padding: $responsive-spacing * 6 !important;
    }
  }
}
// padding top
.rpt {
  &-0 {
    padding-top: $responsive-spacing * 0 !important;
    @include respond-below(sm) {
      padding-top: $responsive-spacing * 0 !important;
    }
  }
  &-1 {
    padding-top: $responsive-spacing * 1 !important;
    @include respond-below(sm) {
      padding-top: $responsive-spacing * 0.5 !important;
    }
  }
  &-2 {
    padding-top: $responsive-spacing * 2 !important;
    @include respond-below(sm) {
      padding-top: $responsive-spacing * 1 !important;
    }
  }
  &-3 {
    padding-top: $responsive-spacing * 3 !important;
    @include respond-below(sm) {
      padding-top: $responsive-spacing * 1.5 !important;
    }
  }
  &-4 {
    padding-top: $responsive-spacing * 4 !important;
    @include respond-below(sm) {
      padding-top: $responsive-spacing * 2 !important;
    }
  }
  &-5 {
    padding-top: $responsive-spacing * 6 !important;
    @include respond-below(sm) {
      padding-top: $responsive-spacing * 3 !important;
    }
  }
  &-6 {
    padding-top: $responsive-spacing * 12 !important;
    @include respond-below(sm) {
      padding-top: $responsive-spacing * 6 !important;
    }
  }
}
.rpr {
  &-0 {
    padding-right: $responsive-spacing * 0 !important;
    @include respond-below(sm) {
      padding-right: $responsive-spacing * 0 !important;
    }
  }
  &-1 {
    padding-right: $responsive-spacing * 1 !important;
    @include respond-below(sm) {
      padding-right: $responsive-spacing * 0.5 !important;
    }
  }
  &-2 {
    padding-right: $responsive-spacing * 2 !important;
    @include respond-below(sm) {
      padding-right: $responsive-spacing * 1 !important;
    }
  }
  &-3 {
    padding-right: $responsive-spacing * 3 !important;
    @include respond-below(sm) {
      padding-right: $responsive-spacing * 1.5 !important;
    }
  }
  &-4 {
    padding-right: $responsive-spacing * 4 !important;
    @include respond-below(sm) {
      padding-right: $responsive-spacing * 2;
    }
  }
  &-5 {
    padding-right: $responsive-spacing * 6 !important;
    @include respond-below(sm) {
      padding-right: $responsive-spacing * 3 !important;
    }
  }
  &-6 {
    padding-right: $responsive-spacing * 12 !important;
    @include respond-below(sm) {
      padding-right: $responsive-spacing * 6 !important;
    }
  }
}
.rpb {
  &-0 {
    padding-bottom: $responsive-spacing * 0 !important;
    @include respond-below(sm) {
      padding-bottom: $responsive-spacing * 0 !important;
    }
  }
  &-1 {
    padding-bottom: $responsive-spacing * 1 !important;
    @include respond-below(sm) {
      padding-bottom: $responsive-spacing * 0.5 !important;
    }
  }
  &-2 {
    padding-bottom: $responsive-spacing * 2 !important;
    @include respond-below(sm) {
      padding-bottom: $responsive-spacing * 1 !important;
    }
  }
  &-3 {
    padding-bottom: $responsive-spacing * 3 !important;
    @include respond-below(sm) {
      padding-bottom: $responsive-spacing * 1.5 !important;
    }
  }
  &-4 {
    padding-bottom: $responsive-spacing * 4 !important;
    @include respond-below(sm) {
      padding-bottom: $responsive-spacing * 2 !important;
    }
  }
  &-5 {
    padding-bottom: $responsive-spacing * 6 !important;
    @include respond-below(sm) {
      padding-bottom: $responsive-spacing * 3 !important;
    }
  }
  &-6 {
    padding-bottom: $responsive-spacing * 12 !important;
    @include respond-below(sm) {
      padding-bottom: $responsive-spacing * 6 !important;
    }
  }
}
// padding left
.rpl {
  &-0 {
    padding-left: $responsive-spacing * 0 !important;
    @include respond-below(sm) {
      padding-left: $responsive-spacing * 0 !important;
    }
  }
  &-1 {
    padding-left: $responsive-spacing * 1 !important;
    @include respond-below(sm) {
      padding-left: $responsive-spacing * 0.5 !important;
    }
  }
  &-2 {
    padding-left: $responsive-spacing * 2 !important;
    @include respond-below(sm) {
      padding-left: $responsive-spacing * 1 !important;
    }
  }
  &-3 {
    padding-left: $responsive-spacing * 3 !important;
    @include respond-below(sm) {
      padding-left: $responsive-spacing * 1.5 !important;
    }
  }
  &-4 {
    padding-left: $responsive-spacing * 4 !important;
    @include respond-below(sm) {
      padding-left: $responsive-spacing * 2 !important;
    }
  }
  &-5 {
    padding-left: $responsive-spacing * 6 !important;
    @include respond-below(sm) {
      padding-left: $responsive-spacing * 3 !important;
    }
  }
  &-6 {
    padding-left: $responsive-spacing * 12 !important;
    @include respond-below(sm) {
      padding-left: $responsive-spacing * 6 !important;
    }
  }
}
