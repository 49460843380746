@import '../../colorPallete.scss';
.field {
  &.fixed-right-addon .control {
    width: 60px;

    &:first-child span {
      // $whiter-ter was originally background color but it was undef
      background-color: white;
      padding: 15px 10px 10px 10px;
    }
  }
}

.control {
  .icon.action-addon {
    cursor: pointer;
    pointer-events: all;
  }
}

.select {
  &:not(.is-multiple):not(.is-loading):after {
    border-color: black;
  }
}
// input height adjustment
.input {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  @include respond-below(sm) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.field.fixed-right-addon {
  .control:first-child span {
    background-color: $cirus-grey;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    @include respond-below(sm) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
}
.dropdown {
  .dropdown-menu {
    width: 100%;
  }
  &.is-rounded {
    .button {
      border-radius: 40px;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      @include respond-below(sm) {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }

    .icon {
      position: absolute;
      right: 15px;
    }
  }

  &.fixed-200 {
    display: block;
    .button {
      min-width: 100%;
      text-align: left;
      span {
        color: $dull;
        font-size: 16px;
        font-weight: 400;
      }
    }

    .dropdown-content {
      width: 100%;
      margin-top: -4px;

      .dropdown-item {
        margin-bottom: 0;
        padding: 15px;
      }

      .dropdown-divider {
        margin: 0;
      }
    }
  }
}
