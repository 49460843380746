@import './colorPallete.scss';
$primary: rgb(238, 74, 92);
$body-size: 12px;
$navbar-height: 5rem;
$input-focus-border-color: $primary;
$input-focus-box-shadow-color: rgba($primary, 0.25);
$button-focus-border-color: $primary;
$button-focus-box-shadow-color: rgba($primary, 0.25);
$tabs-link-hover-border-bottom-color: $primary;
$tabs-link-hover-color: $primary;
$tabs-link-active-color: $primary;
$tabs-boxed-link-active-border-color: $primary;
$tabs-link-active-border-bottom-color: $primary;
$breadcrumb-item-color: $primary;
$dropdown-item-active-background-color: $primary;
$notification-background-color: #f5f8fb;
// font variables
$title-size: 30px;
$title-weight: 800;
$subtitle-size: 14px;
$subtitle-weight: 600;
$subtitle-color: $cirus-black;
$subtitle-family: 'Open Sans', sans-serif;
// steps (or progress) variables
$steps-completed-color: #2cb700;
$steps-active-color: #2cb700;
$steps-marker-size: 1.8;
$steps-divider-size: 0.2em;
$steps-hollow-border-size: 0.15rem;
// dropdown
$dropdown-content-arrow: #000000;
// nav variables
$navbar-item-img-max-height: 2.75rem;

$card-color: #353e47;
$card-highlight-gradient: linear-gradient(
  168deg,
  rgb(238, 74, 92) 0%,
  rgb(252, 151, 135) 100%
);
