.dull {
  color: #7e8c9b;
}
$dull: #7e8c9b;
.dull-light {
  color: #f0f2f4;
}
$dull-light: #f0f2f4;
.cirus-grey {
  color: #f5f8fb;
}
.background-cirus-grey {
  background-color: #f5f8fb;
}
$cirus-grey: #f5f8fb;
.cirus-black {
  color: #353e47;
}
$cirus-black: #353e47;
.green {
  color: #2cb700;
}
$green: #2cb700;
.red {
  color: #ff0000;
}
$red: #ff0000;
.cirus-red {
  color: #ee4b5c;
}
$cirus-red: #ee4b5c;
.cirus-red-dull {
  color: #fed5ce;
}
$cirus-red-dull: #fed5ce;
