.transactionSuccessBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .success-img {
    text-align: center;
    margin-bottom: 28px;
  }
  .title {
    text-align: center;
    font-size: 30px;
  }
  .content {
    text-align: center;
    margin-left:20%;
    margin-right:20%;
    margin-bottom: 30px;
  }
  .wallet {
    font-size: 14px;
  }
  .notification {
    img {
      margin-right: 5px;
    }
    strong {
      font-size: 26px;
    }
  }
  .buttons {
    margin: 0 auto;
    .button {
      width: fit-content;
      align-self: center;
      margin-right: 5px;
    }
  }
}
  