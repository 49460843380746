@import '../../../styles/responsive.scss';
@import '../../../styles/bulmaVariables.scss';

.transfer-origin {
  background-color: #fed5ce;
  figure.image.is-32x32 {
    display: flex;
    justify-content: center;
    img {
      height: inherit;
      width: auto;
    }
  }
}
.transfer-destination {
  padding-top: 10px;
  padding-bottom: 0px;
  @include respond-below(md) {
    padding-bottom: 29px;
    padding-top: 38px;
  }
}
.transfer-input {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  @include respond-below(md) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.transfer-input-icon.level.level-item.is-mobile.icon.is-left {
  height: 5rem;
  figure {
    height: 18px;
    img {
      height: 18px;
    }
  }
  @include respond-below(md) {
    height: 4.2rem;
  }
}
.transfer.coin-select {
  background-color: #f5f8fb;
  height: 5.2rem;
  @include respond-below(md) {
    height: 4.2rem;
    width: 8.5rem;
  }
  @include respond-below(sm) {
    width: 6.5rem;
  }
}
.absolute-container {
  position: relative;
  &:not(:last-child) {
    @include respond-below(md) {
      margin-bottom: 17px;
    }
  }
}
.transfer {
  &.button.is-primary.is-rounded.is-large {
    width: 194px;
  }
  &.full.button {
    background-color: transparent;
    position: absolute;
    top: 11.5px;
    right: 138px;
    width: 7rem;
    z-index: 2;
    @include respond-below(md) {
      position: relative;
      top: 0px;
      right: 0px;
    }
  }
}
.transfer.label {
  height: 1.75rem;
  max-width: 4.2rem;
  color: white;
  background-color: #353e47;
  border-radius: 9999px;
  font-size: 12.8px;
  font-weight: 800;
}

// dropdown chevron styling ; bulma override
.select:after {
  border-color: #353e47 !important;
  border: 2px solid !important;
  border-top: 0px !important;
  border-right: 0px !important;
  top: 71% !important;
  @include respond-below(sm) {
    position: absolute;
    top: 56% !important;
  }
}

.eth-balance {
  @include respond-above(md) {
    padding-bottom: 11px;
  }
}
// swap button styling and positioning
.button.swap {
  height: 51.2px;
  width: 51.2px;
  position: relative;
  transform: translate(0%, 90%);
  @include respond-below(md) {
    transform: translate(0%, 72%);
  }
  .swap-image {
    width: 22.4px;
    margin-top: auto;
    position: absolute;
    top: 11px;
    left: 15px;
  }
}
