@import '../../styles/colorPallete.scss';
@import '../../styles/responsive.scss';
.top-table {
  .columns {
    @include respond-above(sm) {
      &.top-table-first-row {
        border-color: $dull-light;
        border-style: solid;
        border-width: 0;
      }
    }
    .column {
      &.mobile-table-item {
        border-color: $dull-light;
        border-style: solid;
        &:first-child {
          border-width: 0 1px 0px 1px;
          @include respond-below(sm) {
            border-width: 0 1px 1px 0;
          }
        }
        &:last-child {
          border-width: 0;
          @include respond-below(sm) {
            border-width: 0 0px 1px 0;
          }
        }
      }
      .mobile-table-row {
        .mobile-table-item {
          padding: 22px 0 16px 23px;
        }
      }
    }
  }
}

.expand-metrics-text {
  font-weight: 600;
}
.expandable-table {
  transition: height 0.3s ease;
  overflow: hidden;
  height: fit-content;
  .column {
    border-bottom: 0;
  }
}
.btn-expand-metrics {
  padding-top: 16.5px;
  padding-bottom: 18px;
}
