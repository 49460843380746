.table.mantra-table {
  thead th {
    color: #7e8c9b;
    font-size: 10px;
    padding-left: 40px;
  }

  td {
    &:nth-child(2) {
      padding-left: 0;
    }
    padding: 20px 28px 20px 12px;
    font-size: 12px;
    font-weight: bold;
    vertical-align: middle;
  }

  tr.is-active {
    background: #ee4a5c;
    background: linear-gradient(180deg, #ee4a5c 10%, #fc9787 100%);

    td {
      color: white;
      .amount-box {
        .media-content {
          .bottom-txt {
            color: white;
          }
        }
      }
    }
  }
}
