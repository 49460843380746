@import '../../styles/responsive.scss';
@import '../../styles/colorPallete.scss';

.topNavbar {
  position: relative;
  z-index: 10;
  &.sticky-navbar {
    position: sticky;
    top: 0;
  }
  .container {
    display: flex !important;
  }
}

.navbar {
  border-color: white;
  border-style: solid;
  border-width: 0 0 0px 0;
  &.is-transparent {
    background-color: transparent;
  }
  .container {
    justify-content: space-between;
    .wrong-network {
      img {
        height: 25px;
      }
      .helper-icon {
        height: 25px;
        position: relative;
        border: none;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-right: 10px;
        .info {
          display: none;
        }
        &:hover, &:focus {
          .info {
            background-color: white;
            border-radius: 12px;
            padding: 1.5rem;
            display: block;
            position: absolute;
            top: 40px;
            width: 275px;
            text-align: left;
          }
          .uptick {
            display: block;
            position: absolute;
            height: 25px;
            width: 25px;
            background-color: white;
            transform: rotate(45deg);
            top: 30px;
          }
        }
      }
      .danger{
        background-color: #f20000;
        color: white !important;
        border-color: white;
        border-width: 1px;
        border-style: solid;
        border-radius: 9999px;
        padding: 1rem 1.25rem;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 20px;
        }
      }
    }
    .connect-wallet {
      margin-right: 0px;
      display: flex;
      align-items: center;
      .info {
        display: none;
      }
      .navbar-top-table {
        flex: 1 1  auto;
      }
      .wallet-btn {
        min-width: 136px;
        
        // flex: 0 0 136px;
        @include respond-below(md) {
          padding-right: 15px;
          min-width: 151px;
          // flex: 0 0 151px;
        }
        
      }
    }
  }
}

.breadcrumb-section {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 40px;
  border: 1px solid $dull-light;
  @include respond-below(md) {
    padding-left: 20px;
  }
  .container {
    .breadcrumb {
      position: absolute;
      li:first-child {
        a:first-child {
          padding-right: 0;
          img {
            margin-bottom: 2px;
          }
        }
        a {
          height: 18px;
        }
      }
    }
  }
}
.navbar-brand {
  .navbar-item {
    @include respond-below(md) {
      padding-top: 16px;
      padding-left: 15px;
      padding-bottom: 16px;
    }
  }
}

.mobile.wallet.button.is-medium.is-rounded.has-text-primary {
  
  @include respond-below(md) {
    right: 15px;
    top: -51px;
  }
}
