@import '../../styles/responsive.scss';

.signTransactionBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
  @include respond-below(sm) {
    padding: 20px;
  }

  .title {
    text-align: center;
    font-size: 30px;
  }
  .textCenter {
    text-align: center;
  }
  .notification {
    display: flex;
    flex-direction: column;
    &.is-tight {
      width: auto;
      padding: 0px;
      min-width: 320px;
      max-width: 100%;
      @include respond-below(xxs) {
        min-width: auto;
      }
    }
    .notification-title {
      font-weight: 700;
      margin: 18px 0 6px 0;
      @include respond-below(xxs) {
        margin: 5px 0 5px 0;
      }
    }
    .notification-amount {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 30px;
      margin-bottom: 18px;
      @include respond-below(xxs) {
        margin-bottom: 10px;
      }
    }
    .cirus-red {
      color: #ee4b5c;
    }
    img {
      margin-right: 10px;
      border-radius: 50%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.148326);
    }
  }
  .buttons {
    margin: 15px auto 0;
    .button {
      width: fit-content;
      align-self: center;
    }
  }
}