@import './../../styles/colorPallete.scss';
@import './../../styles/bulmaVariables.scss';
.top-info-bar {
  background-color: $primary;
  color: white;
  width: 100%;
  .column {
    padding: 15px 8px;
    &:first-child {
      padding-left: 15px;
    }
    &:last-child {
      padding-right: 15px;
    }
    .amount-box {
      .column {
        .media-content {
          .top-txt {
            color: #ffc2b8;
          }
          .middle-txt {
            font-size: 14px;
          }
          .bottom-txt {
            color: white;
          }
        }
      }
    }
  }
}
