@import './../../styles/responsive.scss';
a.is-primary {
  color: #ee4a5c;
}
.overflow-hidden {
  overflow: hidden;
}
@include respond-above(sm) {
  .transfer-card {
    padding-left: 15px;
  }
  .balance-card {
    padding-right: 15px;
  }
  .convert-balance-history-card {
    padding-top: 26px;
  }
}
@include respond-below(sm) {
  section.convert-tokens.section {
    padding-top: 9px;
  }
}
